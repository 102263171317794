import request from "@/utils/request";
import qs from 'qs'

/**
 * 登录接口
 * @param data
 * @returns {*}
 */
export const loginApi = data => {
    return request({
        url: '/FileUpLoadApp/SqlDataGet.ashx?pars='+data,
        method: 'post',
        // data:data
    })
}

/**
 * 获取验证码
 * @param data
 * @returns {*}
 */
export const getCode = data => {
    return request({
        url: '/Sendsms/GetCode',
        method: 'post',
        data
    })
}

/**
 * 用户注册
 * @param data
 * @returns {*}
 */
export const registered = data => {
    return request({
        url:'/FileUpLoadApp/SqlDataGet.ashx?pars='+data,
        method: 'post',
        // data
    })
}
