import request from "@/utils/request";

/**
 * 全局统一的数据请求api
 * @param data
 * @returns {*}
 */
export const requestApi = (data, config={}) => {
    return request({
        url: '/FileUpLoadApp/SqlDataGet.ashx?pars='+JSON.stringify(data),
        method: 'post',
        ...config
    })
}
