import router from "./router";
import store from "./store";
import {getToken} from '@/utils/auth'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({showSpinner: false}) // NProgress Configuration
const whiteList = ['/login', '/registered'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
    NProgress.start()
    next()
    // const token = getToken()
    // if (token) {
    //     next()
    // } else {
    //     await store.dispatch('user/loginOut')
    //     /* has no token*/
    //     if (whiteList.indexOf(to.path) !== -1) {
    //         // in the free login whitelist, go directly
    //         next()
    //     } else {
    //         // other pages that do not have permission to access are redirected to the login page.
    //         next(`/login`)
    //     }
    // }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
