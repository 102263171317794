const state = {
    feedbackStatus:false
}
const mutations = {
    SET_FEEDBACK_STATUS:(state, status)=>{
        state.feedbackStatus = status
    }
}
const actions = {
    setFeedbackStatus({commit}, status){
        commit('SET_FEEDBACK_STATUS', status)
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
