import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/special-line',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login')
      },
      {
        path: '/registered',
        name: 'registered',
        component: () => import('@/views/registered/index')
      },
      {
        path: '/special-line',
        name: 'special-line',
        component: () => import('@/views/special-line/index')
      },
      {
        path: '/map',
        name: 'map',
        component: () => import('@/views/map')
      },
      {
        path: '/offer-search',
        name: 'offer-search',
        component: () => import('@/views/offer-search')
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test')
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('@/views/news'),
        children: [
          {
            path: 'news-item/:id',
            name: 'news-item',
            component: () => import('@/views/news/news-item')
          },
          {
            path: 'news-detail',
            name: 'news-detail',
            component: () => import('@/views/news/news-detail')
          }
        ]
      }
    ]
  },
  { path: '*', component: () => import('@/views/error-page/404') }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior: () => ({ y: 0 }),
})


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
