const state = {
    specialLine: [],
    offerSearch: []
}
const mutations = {
    SET_SPECIAL_LINE: (state, data) => {
        state.specialLine = data
    },
    SET_OFFER_SEARCH: (state,data) => {
        state.offerSearch = data
    },
    // 删除报价查询历史
    DEL_OFFER_SEARCH: (state, index) => {
        let arr = [...state.offerSearch]
        if (index !== null){
            arr.splice(index,1)
        }else {
            arr = []
        }
        state.offerSearch = arr
    },
    // 删除专线查询历史
    DEL_SPECIAL_LINE: (state, index) => {
        let arr = [...state.specialLine]
        if (index !== null){
            arr.splice(index,1)
        }else {
            arr = []
        }
        state.specialLine = arr
    }
}

const actions = {
    // 报价查询的搜索历史
    setOfferSearch({commit, state},data){
        commit('SET_OFFER_SEARCH', [...state.offerSearch,data])
    },
    // 专线查询的搜索历史
    setSpecialLine({commit, state}, data) {
        commit('SET_SPECIAL_LINE', [...state.specialLine, data])
    },
    remoteOfferSearch({commit}, index =null){
        commit('DEL_OFFER_SEARCH', index)
    },
    remoteSpecialLine({commit}, index=null){
        commit('DEL_SPECIAL_LINE', index)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
