import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './permission'
import '@/styles/index.scss' // global css

import MetaInfo from 'vue-meta-info'
import SlideVerify from 'vue-monoplasty-slide-verify';
import Element from 'element-ui'
import './styles/element-variables.scss'
import animated from 'animate.css'

import EffectInput from 'effect-input'
import 'effect-input/dist/index.css'

Vue.use(EffectInput)
Vue.use(animated)

import {requestApi} from '@/api/api'

Vue.config.productionTip = false
Vue.prototype.$api = requestApi

Vue.use(SlideVerify)
Vue.use(MetaInfo)

import ShowInputBox from '../src/views/map/InputBox'
Vue.use(Element, {
  size: 'small', // set element-ui default size
  // locale: enLang // 如果使用中文，无需设置，请删除
})

Vue.use(ShowInputBox)
const sourceWarn = window.console.warn
// 复写warn方法方便检查由于使用name跳转无法监控404的问题
window.console.warn = function (...rest) {
  const args = Array.from(rest)
  if (args[0] && args[0].includes('Route with name') && args[0].includes('does not exist')) {
    setTimeout(() => {
      router.push('/404')
    }, 0)
  }
  sourceWarn(...rest)
}

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
