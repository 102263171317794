import InputBox from '../map/InputBox.vue'
let ConfirmConstructor, instance
 
const showInputBox = {
  install(Vue) {
    ConfirmConstructor = Vue.extend(InputBox)
    instance = new ConfirmConstructor().$mount()
    document.body.appendChild(instance.$el)
 
    Vue.prototype.$showInputBox = options => {
      Object.assign(instance, options)
      instance.init()
    }
 
  }
}
 
export default showInputBox