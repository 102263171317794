import axios from 'axios'
import {Loading, Message} from 'element-ui'
import store from '@/store'
import {getToken} from '@/utils/auth'
import router from '@/router'

// create an axios instance
let loading;
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    withCredentials: true, // send cookies when cross-domain requests
    timeout: 60 * 1000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // 是否显示全局loading，判断是否有传进来loading true或false，默认true
        let isLoading = typeof config.loading == 'boolean' ? config.loading : true
        if (isLoading)
            loading = Loading.service({
                text: '请稍后...',
                background: 'rgba(255,255,255,.3)'
            })
        // if (store.getters.token) {
        //     config.headers.Authorization = getToken()
        // }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */
    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    async response => {
        const res = response
        loading?.close() // 如果有loading，就关闭
        console.log('res',res)
        if (res.data.result == 0 || res.data.code == 200) {
            // 成功
            if (res.headers.tmsbstoken) res.data.tmsbstoken = res.headers.tmsbstoken
            return res.data
        } else {
            console.log('err', res)
            Message({
                message: res.data.msg || res.data.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
            return Promise.reject(res.data.result || 'Error')
        }

        // if (res.data.result != 0 || res.data.code != 200){
        //   console.log('err',res)
        //   Message({
        //     message: res.data.msg || 'Error',
        //     type: 'error',
        //     duration: 5 * 1000
        //   })
        //   return Promise.reject(res.data.result || 'Error')
        // } else {
        //   console.log('success',res.headers)
        //   if (res.headers.tmsbstoken) res.data.tmsbstoken = res.headers.tmsbstoken
        //   return res.data
        // }
    },
    error => {
        loading?.close()
        console.log('err' + error) // for debug
        let {status} = error.response
        let msg = error.response.data
        if (status == 404) { // 访问的接口不存在
            msg = msg || '访问的接口不存在'
        } else if (status == 401) {// token值无效
            msg = msg || 'token值无效，请重新登录'
            router.push('/login')
        }
        Message({
            message: msg,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
