<template>
  <div ref="rightPanel" v-if="isShow" :class="{ show: show }" class="rightPanel-container">
    <div class="rightPanel-background"/>
    <div class="rightPanel">
      <!-- @click="show=!show" -->
      <div class="handle-button"
          :style="{ top: buttonTop + 'px', 'background-color': theme }">
        <div class="box" @click="openQQ">
          <i :class="show ? 'el-icon-close' : 'el-icon-user-solid'"/>
          <p>客服QQ</p>
        </div>
        <div class="box" @click="openPhone">
          <i :class="show ? 'el-icon-close' : 'el-icon-phone'"/>
          <p>电话咨询</p>
        </div>
        <div class="box" @click="openForm">
          <i :class="show ? 'el-icon-close' : 'el-icon-edit-outline'"/>
          <p>意见反馈</p>
        </div>
      </div>
      <div class="rightPanel-items">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
import {addClass, removeClass} from "@/utils";
import {mapActions, mapGetters} from 'vuex'

export default {
  name: "RightPanel",
  props: {
    clickNotClose: {
      default: false,
      type: Boolean,
    },
    buttonTop: {
      default: 250,
      type: Number,
    },
  },
  data() {
    return {
      show: false,
      qq: '2085759905',
    };
  },
  computed: {
    ...mapGetters([
      'feedbackStatus'
    ]),
    theme() {
      return `#fff`;
    },
    // 如果弹出意见反馈弹出，就隐藏右侧按钮
    isShow(){
      return !this.feedbackStatus
    }
  },
  watch: {
    show(value) {
      if (value && !this.clickNotClose) {
        this.addEventClick();
      }
      if (value) {
        addClass(document.body, "showRightPanel");
      } else {
        removeClass(document.body, "showRightPanel");
      }
    },
  },
  mounted() {
    this.insertToBody();
  },
  beforeDestroy() {
    const elx = this.$refs.rightPanel;
    elx.remove();
  },
  methods: {
    ...mapActions('app',[
        'setFeedbackStatus'
    ]),
    openForm(){
      this.setFeedbackStatus(true)
    },
    openPhone() {
      this.$alert('400-021-5656', '客服电话', {
        confirmButtonText: '确定',
        // callback: action => {
        //   this.$message({
        //     type: 'success',
        //     message: `action: ${ action }`
        //   });
        // }
      });
    },
    // 打开qq
    openQQ() {
      window.open('http://wpa.qq.com/msgrd?v=3&uin=' + `${this.qq}` + '&site=qq&menu=yes', '_brank');
    },
    addEventClick() {
      window.addEventListener("click", this.closeSidebar);
    },
    closeSidebar(evt) {
      const parent = evt.target.closest(".rightPanel");
      if (!parent) {
        this.show = false;
        window.removeEventListener("click", this.closeSidebar);
      }
    },
    insertToBody() {
      const elx = this.$refs.rightPanel;
      const body = document.querySelector("body");
      body.insertBefore(elx, body.firstChild);
    },
  },
};
</script>

<style>
.showRightPanel {
  overflow: hidden;
  position: relative;
  width: calc(100% - 15px);
}
</style>

<style lang="scss" scoped>
$rightColor: #01468c;
.rightPanel-background {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  background: rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.rightPanel {
  width: 100%;
  max-width: 260px;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
  transform: translate(100%);
  background: #fff;
  z-index: 40000;
}

.show {
  transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);

  .rightPanel-background {
    z-index: 20000;
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  .rightPanel {
    transform: translate(0);
  }
}

.handle-button {
  width: 72px;
  // height: 72px;
  box-shadow: 2px 2px 2px 2px #ccc;
  position: absolute;
  left: -72px;
  text-align: center;
  font-size: 24px;
  border-radius: 6px 0 0 6px !important;
  z-index: 0;
  pointer-events: auto;
  cursor: pointer;
  color: $rightColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .box {
    width: 100%;
    // margin-bottom: 10px;
    padding: 5px 0;

    &:hover {
      background: $rightColor;
      color: #fff;
    }

    i {
      font-size: 20px;
      line-height: 38px;
    }

    p {
      font-size: 12px;
      font-weight: 400;
    }
  }
}
</style>
