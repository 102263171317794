<template>
  <div>
    <el-container>
      <el-header height="115px" class="el-header">
        <navbar></navbar>
        <top-menu></top-menu>
      </el-header>
      <el-main>
        <AppMain></AppMain>
        <footer-info></footer-info>
        <right-panel></right-panel>
        <!--意见反馈弹出-->
        <feedback></feedback>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import AppMain from "./AppMain.vue";
import TopMenu from "@/components/TopMenu";
import Navbar from "@/components/Navbar";
import FooterInfo from '@/components/FooterInfo'
import RightPanel from '@/components/RightPanel'
import Feedback from '@/components/Feedback'

export default {
  components: {
    AppMain,
    Navbar,
    TopMenu,
    FooterInfo,
    RightPanel,
    Feedback
  },
  data() {
    return {}
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
::v-deep .el-header{
  box-shadow: 0 2px 4px #ccc;
  margin-bottom: 10px;
  z-index: 99;
}
.el-main {
  padding: 0 !important;
}
</style>
