import {getToken, removeToken} from '@/utils/auth'
import {loginApi} from "@/api/user";

const state = {
    token: getToken(),
    userInfo: {
        user_name: null
    }
}
const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_USER_INFO: (state, info) => {
        state.userInfo = {...info}
    }

}

const actions = {
    /**
     * 登录
     * @param commit
     * @param userinfo
     * @returns {Promise<unknown>}
     */
    login({commit}, userinfo) {
        return new Promise((resolve, reject) => {
            let params = {
                proc: "QSP_GET_Login_WxApp",
                method: 'Query',
                pars: [
                    {mb: userinfo.username.trim(), passwd: userinfo.password}
                ],
                tmscode: "89569",
                loginsite: "全部",
            }
            // 前端不需要手动存储Token，后端写入
            loginApi(JSON.stringify({...params})).then(res => {
                commit('SET_USER_INFO', res.msg[0])
                resolve(res)
            }).catch(err => {
                console.log('err', err)
                reject(err)
            })
        })
    },
    // 退出登录
    loginOut({commit, dispatch}) {
        commit('SET_USER_INFO', {})
        // 清除搜索历史
        dispatch('searchHistory/remoteSpecialLine', null, {root: true})
        removeToken()
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
