<template>
  <el-drawer
      title="意见反馈"
      :visible.sync="feedbackStatus"
      direction="rtl"
      :wrapperClosable="false"
      destroy-on-close
      :before-close="handleClose">
    <div class="demo-drawer__content" v-if="feedbackStatus">
      <el-form ref="form" size="medium" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="用户名" prop="operman">
          <el-input v-model="form.operman" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mb">
          <el-input v-model="form.mb" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="反馈内容" prop="content">
          <el-input type="textarea" rows="5" v-model="form.content" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div style="text-align: center" class="dialog-footer">
      <el-button size="medium" icon="el-icon-close" @click="handleClose">取 消</el-button>
      <el-button size="medium" icon="el-icon-s-promotion" type="primary" @click="submit">确 定</el-button>
    </div>
  </el-drawer>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  name: "index",
  data() {
    return {
      form: {
        operman: '',
        mb: '',
        content: ''
      },
      rules: {
        operman: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        mb: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
        content: [
          {required: true, message: '请输入', trigger: 'blur'},
        ],
      }
    }
  },
  computed: {
    ...mapGetters([
      'feedbackStatus'
    ])
  },
  methods: {
    ...mapActions('app', [
      'setFeedbackStatus'
    ]),
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            method:"Modify",
            proc:"USP_ADD_FEEDBACK",
            pars:[
              {...this.form}
            ],
            tmscode:"89569",
            loginsite:"全部",
            token:""
          }
          this.$api(params).then(res => {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.$refs['form'].resetFields()
            this.handleClose()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    // 关闭弹窗
    handleClose() {
      this.$nextTick(() => {
        this.$refs['form'].resetFields()
        this.setFeedbackStatus(false)
      })

    }
  }
}
</script>

<style lang="scss" scoped>
.demo-drawer__content{
  padding: 0 20px;
}
</style>
