<template>
  <div class="content">
    <div class="right-box">
      <span>您好 {{ userInfo.user_name || '' }}！欢迎来到中国物流网</span>
      <div v-if="isShowUserName" class="inlineBlock">
        <span class="txt-btn pointer op-7" @click="loginout">退出登录</span>
      </div>
      <div v-else class="inlineBlock">
        <span class="txt-btn pointer op-7" @click="goLogin">登录</span>
        <span class="txt-btn pointer op-7" @click="goRegistered">免费注册</span>
      </div>
    </div>
    <div style="float: right;display: flex;">
      <viewer>
        <img src="../../assets/司机.png" style="width: 90px; margin-left: 5px;" alt="">
      </viewer>
      <span style="word-wrap: break-word;width: 10px" >车主版下载</span>
      <viewer>
        <img src="../../assets/货主.png" style="width: 90px; margin-left: 5px;" alt="">
      </viewer>
      <span style="word-wrap: break-word;width: 10px" >货主版下载</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    isShowUserName() {
      return !!this.userInfo.user_name
    }
  },
  methods: {
    ...mapActions('user', [
      'loginOut'
    ]),
    // 退出登录
    loginout() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        this.loginOut()
        // this.goLogin()
        this.$message({
          message: '您好，您已成功退出登录!',
          type: 'info'
        })
      }).catch(err => {
        console.log(err)
      })
    },
    // 跳转登录
    goRegistered() {
      this.$router.push({
        name: 'registered'
      })
    },
    goLogin() {
      this.$router.push({
        name: 'login'
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 36px;
  background: #ffffff;
  font-size: 12px;
  // display: flex;
  // justify-content: flex-start;
  // align-items: center;

  .inlineBlock {
    display: inline-block;

    .op-7 {
      &:hover {
        opacity: .7;
      }
    }
  }

  .right-box {
    margin-left: 30px;

    span {
      font-family: SourceHanSansCN-Regular;
      color: #333333;
      font-weight: 400;
      margin-left: 20px;
    }

    .txt-btn {
      color: #00468C;
    }
  }
}
</style>
