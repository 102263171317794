<template>
    <div v-if="show" class="mask">
        <div class="dlg-input-box flex flex-col">
            <div class="flex flex-space-between full-width">
                <div class="font-bold">{{ caption }}</div>
            </div>
            <div class="margin-top-xl flex flex-col">
                <label>名称：</label>
                <input class="input-box" placeholder="请输入" v-model="name" ref="getfocus" /><br />
                <label>备注：</label>
                <input class="input-box" placeholder="请输入" v-model="remark" ref="getfocus" />
            </div>
            <div class="flex flex-end margin-top-xl">
                <div class="btn-huibai-auto pointer" style="width:80px" @click="cancelClick">取消</div>
                <div class="margin-left-m btn-blue-auto pointer" style="width:80px" @click="confirmClick">确定</div>
            </div>
        </div>
    </div>
</template>
   
<script>

export default {
    name: 'InputBox',
    props: {
        caption: {},
        value: {},
        show: {},
        callback: {}
    },
    watch: {
        show(val) {
            if (val == true) {
                this.$nextTick(() => {
                    this.$refs.getfocus.focus();
                })
            }
        },
        value(val) {
            console.log(val)
            this.name = val.name;
            this.remark = val.remark;
        }
    },
    data() {
        return {
        }
    },
    methods: {
        close() {
            this.show = false;
        },
        init() {
            this.show = true;
        },
        confirmClick() {
            if (this.name == "") {
                this.$showToast({
                    msg: '内容未填写',
                    duration: 2000
                })
            } else {
                this.callback({ name: this.name, remark: this.remark, isconfirm: true })
                this.show = false;
            }
        },
        cancelClick() {
            this.callback({ name: this.name, remark: this.remark, isconfirm: false })
            this.show = false;
        }

    }
}
</script>
   
<style>
.dlg-input-box {
    border-radius: 5px;
    width: 350px;
    height: 160px;
    background-color: #fff;
    padding: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
</style>