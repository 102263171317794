<template>
  <div class="content">
    <div class="left_box">
      <img class="logo" title="中国物流网,物流,货源,车源,专线" src="../../assets/logo2.png" alt="中国物流网,物流,货源,车源,专线"/>
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          :collapse="isCollapse"
      >
        <el-menu-item class="transform" index="special-line">专线查询</el-menu-item>
        <el-menu-item class="transform" index="map">物流地图</el-menu-item>
        <el-menu-item class="transform" index="offer-search">报价查询</el-menu-item>
        <el-menu-item class="transform" index="news">新闻资讯</el-menu-item>
        <!-- <el-menu-item class="transform" index="test">关于我们</el-menu-item> -->
      </el-menu>
    </div>

    <!-- <div class="right_box">
      <el-radio-group v-model="type" size="mini">
        <el-radio-button label="货源"></el-radio-button>
        <el-radio-button label="车源"></el-radio-button>
        <el-radio-button label="专线"></el-radio-button>
      </el-radio-group>
      <div class="input_box">
        <el-input placeholder="请输入关键字" v-model="input"></el-input>
        <el-button icon="el-icon-search" type="primary" @click="goSearch">立即搜索</el-button>
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      // activeIndex: this.$route.name,
      type: "专线",
      input: "",
      isCollapse:false, // 是否折叠顶部菜单
    };
  },
  created() {
    // window.addEventListener('resize', this.handleResize)
  },
  computed: {
    activeIndex() {
      return this.$route.name
    }
  },
  methods: {
    handleResize(){
      console.log(document.documentElement.clientWidth)
      if (document.documentElement.clientWidth <= 1080)
        this.isCollapse = true
      else
        this.isCollapse = false
    },
    goSearch() {
      let msg, name = null
      switch (this.type) {
        case "专线":
          name = 'special-line';
          break
        default:
          msg = '该类型搜索暂未开放，敬请期待'
          break
      }
      if (name) {
        this.$router.push({
          name: name,
          query: {
            input: this.input
          }
        })
        // this.input = ''
      } else {
        this.$message({
          message: msg,
          type: 'warning'
        })
      }
    },
    handleSelect(e) {
      this.$router.push({
        path: '/' + e
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;

  .left_box {
    display: flex;
    align-items: center;

    .logo {
      width: 156px;
      height: 52px;
      margin-right: 20px;

      &:hover {
        animation: heartBeat 2s;
      }
    }

    ::v-deep .el-menu-demo {
      display: flex;
      flex-wrap: nowrap;
      background: rgba(255, 255, 255, 0);

      .el-menu-item {
        background: rgba(255, 255, 255, 0);

        &:hover {
          animation: heartBeat 1s;
          animation-iteration-count: 1;
        }
      }
    }
  }

  .right_box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 30%;

    ::v-deep.input_box {
      margin-top: 5px;
      width: 100%;
      display: flex;

      .el-input__inner {
        border-radius: 0 !important;
      }

      .el-button {
        border-radius: 0 !important;
      }
    }
  }
}
</style>
